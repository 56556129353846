import React from "react"
import { graphql } from "gatsby"

import { Layout, Slices, SEO } from "../../components"

const FrontPage = ({ data, pageContext }) => {
  const doc = data.frontpage

  return (
    <Layout>
      <SEO seo={doc.seo} />
      <Slices input={doc.content} context={pageContext} />
    </Layout>
  )
}

export default FrontPage

export const query = graphql`
  query Frontpage {
    frontpage: sanityFrontPage {
      id
      seo {
        title
        description
      }
      content {
        ... on SanityHero {
          _key
          _type
          title
          animation
          _rawBlock(resolveReferences: { maxDepth: 10 })
        }

        ... on SanityReferenceTheses {
          _key
          _type
          theses {
            _id
            number
            title
            _rawBody
          }
          title
        }

        ... on SanityReferencePosts {
          _key
          _type
          cta
          title
          _rawIntro(resolveReferences: { maxDepth: 10 })
          post {
            _id
            _key
            id
            externalLink
            external
            title
            intro {
              children {
                text
              }
            }
            slug {
              current
            }
            mainImage {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }

        ... on SanityTextImagePage {
          _key
          _type
          direction
          title
          _rawText(resolveReferences: { maxDepth: 10 })
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }

        ... on SanityGallery {
          _key
          _type
          _rawIntro(resolveReferences: { maxDepth: 10 })
          person {
            name
            _rawBio
            _key
            image {
              asset {
                fluid(maxWidth: 628, maxHeight: 555) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          title
          direction
        }
        ... on SanityReferenceProcess {
          _key
          _type
          title
          _rawIntro
          process {
            _id
            title
            icon {
              asset {
                url
              }
            }
            body {
              children {
                text
              }
            }
          }
        }
      }
    }
  }
`
